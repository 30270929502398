<template>
  <p class="atalhos">
    <a href="javascript:history.back(-1);" title="Voltar">Voltar</a> | <VLink href="/" title="Página incial">Página inicial</VLink>
  </p>
</template>

<script>
import VLink from "./VLink";

export default {
  name:'Atalhos',
  components:{
    VLink
  }
}
</script>

<style>

</style>
