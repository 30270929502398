<template>
    <div class="container-fluid search">
        <div class="container search-box">
            <div class="row">
                <form method="post" action="calendario/" @submit.prevent>
                    <div class="col-md-12">
                        <h3>
                            Procure um evento<span class="pull-right small top"><a href="/limpar-filtros/"
                                    @click.prevent="search = ''">Limpar filtros</a></span>
                        </h3>
                    </div>
                    <div class="col-md-10">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="search" id="txtNome" name="txtNome" placeholder="Digite a palavra chave (Nome do evento, Local, Data, etc...)">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group btn-full">
                            <button type="submit" class="btn btn-danger ">FILTRAR</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "SeachBar",
    data() {
        return {
            search: '',
        };
    },
});

</script>

<style scoped></style>
