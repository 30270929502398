<template>
  <HeaderHome />
  <Loader :show="show_loader"/>
  <FiltroEvento />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Faça seu Login</h1>
          <p class="text-justify">
            Preencha seus dados abaixo e acesse seu painel ou crie uma conta
            agora mesmo.
          </p>
          <div class="container-fluid form-box2">
            <form ref="login" @submit.prevent="login">
              <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>E-mail *</label>
                  <input v-model="email" name="email" type="email" class="form-control" required="required" placeholder="E-mail" autocomplete="off" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Senha *</label>
                  <input v-model="password" name="password" type="password" class="form-control" required="" placeholder="Senha" autocomplete="off" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group text-center">
                  <button @click.prevent="login" class="btn btn-success">
                    EFETUAR LOGIN
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <p class="text-center">
                  <VLink href="/esqueci-minha-senha/">Esqueci minha senha</VLink>
                  / <VLink href="/cadastre-se/">Efetuar cadastro</VLink>.
                </p>
              </div>
            </div>
            </form>
          </div>
          <Atalhos />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome.vue";
import FiltroEvento from "../components/FiltroEvento.vue";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import VLink from "@/components/VLink";
import User from "@/api/User";
import Auth from "@/api/Auth";
import Loader from "../components/Loader";

import Swal from "sweetalert2";

export default defineComponent({
  name: "LoginPage",
  components: {
    HeaderHome,
    VLink,
    FiltroEvento,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
    Loader
  },
  data() {
    return {
      email: "",
      password: "",
      show_loader:true,
    };
  },
  methods: {
    login() {
      if(!this.$refs.login.checkValidity()){
        this.$refs.login.reportValidity();
        return;
      }
      this.show_loader = true;

      let user = User.login(this.email, this.password);
      user.then((request) => {
        if (request.status == 200) {
          Auth.set_token(request.data.access_token);
          User.profile(request.data.access_token).then((request2) => {
            if (request2.status == 200) {
              Auth.set_user(request2.data);

              this.show_loader = false;
              this.$router.push("/minha-conta/");
              window.scrollTo(0, 0);
            }
          });
        }else{
          Swal.fire({
                icon: 'error',
                title: 'E-mail ou senha inválida.',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar:true,
              }
          );

          this.show_loader = false;
        }
      });
    },
  },
  mounted() {
    this.show_loader = false;
  }
});
</script>

<style>
</style>
